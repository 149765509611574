import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image_mobile = require("../../../assets/img/blogs/strategic_blog_header_mob.png");
const header_image = require("../../../assets/img/blogs/strategic_blog_header.png");
const section_1 = require("../../../assets/img/blogs/strategic_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/strategic_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/strategic_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/strategic_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/strategic_blog_img_5.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });

  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="Mastering User Query Resolution through Strategic Knowledge AI Integration"
        description="Leverage Knowledge AI to build the synergy between LLM and customer support. Workativ Knowledge AI supercharges human creativity to maximize AI automation for your employee support."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt strategic_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="ffont-page-header-home-blog-smb color-black line-height-18">
                    Strategic Synergy: Integrating Knowledge AI for Seamless
                    Resolution of User Queries
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-smb  color-black">
                    Strategic Synergy:
                    <br /> Integrating Knowledge AI <br />
                    for Seamless Resolution
                    <br /> of User Queries
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Implications of overlooking robust partnership of Knowledge
                  AI and employee support
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Why use Knowledge AI for your employee support?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Ways of elevating employee support for resolution of user
                  queries with Knowledge AI (use cases )
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Workativ as a catalyst to promote strategic synergy for
                  businesses with Knowledge AI
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                User queries can comprise different essences. A strategy built
                to satisfy user queries may need a change periodically, too. A
                unique user request aims to leverage a new approach to
                addressing the problem, delivering a consistent experience for
                internal employees, and building robust customer relationships.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Every strategy for synergy a company picks up involves the same
                underlying objective — unified communications and coordination
                between departments, teams, and various operations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At times when{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-dummies">
                  Generative AI
                </a>{" "}
                unleashes phenomenal competencies, Knowledge AI — a custom large
                language model with a company-owned data repository fosters
                unique strategic synergy for business leaders.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Marrying{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                with internal customer support builds unique partnerships
                between a machine and a human, which brings transformational
                change to support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The synergy between machine or Knowledge AI and employee support
                weaves a unique experience for humans by automating multiple
                mundane tasks.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Using human creativity and unique problem-solving skills with
                Knowledge AI helps accelerate automation capabilities in the
                knowledge discovery area and derive critical insights to
                facilitate fantastic business results.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We can say that Knowledge AI or machines and humans are integral
                to each other – they combine to become superpowers and craft
                beautiful experiences for your{" "}
                <a href="https://workativ.com/conversational-ai-platform/workplace-support-bot">
                  employee support.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Implications of overlooking robust partnership of Knowledge AI
                and employee support
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses want processes to be easy and fast for their
                employees so that they can optimize their productivity and drive
                tangible outcomes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge is critical — and your chatbot emerges as the first
                point of contact to help address a problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What if your chatbot supplies information that lacks context —
                it frustrates your people and drains their time.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Operational efficiency is at stake. Your{" "}
                    <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                      employee support chatbot
                    </a>{" "}
                    does not provide enough information to help solve a problem.
                    For example, a user shuffles through different articles to
                    get access to business applications. It contains many pages
                    to navigate, but the correct answers sit aloof. Your
                    self-service portal fails you, plus triggers challenges for
                    your agent. Overall, your team sustains a loss of
                    operational efficiency.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Employee morale takes a hit. A rule-based or keyword-based
                    chatbot lacks contextual awareness and intent
                    identification. Employees become frustrated as they must
                    repeatedly type before seeing a rather disappointed
                    response.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Brand loyalty can also drop as employees exhibit less
                    interest in becoming loyal advocates and promoting the brand
                    among its community and groups.
                  </span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI supercharges the availability of information and
                acts as an efficient and powerful component or technology to
                build the finest human-machine relationship to transform
                employee support and provide seamless resolution for user
                queries.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why use Knowledge AI for your employee support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Your employee support could emerge as the best medium to act as
                an effective synergy between your company and internal users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But how often did you realize its answers are complicated,
                incorrect, or irrelevant because it couldn’t recall what was
                said earlier in a chat session?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is a possible scenario with a chatbot for an employee
                support system.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI empowers your{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-automation">
                  employee support system
                </a>{" "}
                to supply information for employees they want to find and solve
                problems.
              </p>
              <img src={section_1} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                By effectively enhancing communications and coordination for
                every department in your organization, Knowledge AI allows
                people to leverage the benefits of strategic synergy between
                technology and employee support systems.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    With domain-specific knowledge, the{" "}
                    <a href="https://workativ.com/conversational-ai-platform/blog/knowledge-ai-employee-support">
                      Knowledge AI chatbot
                    </a>{" "}
                    can streamline operations for human, increase their
                    productivity, and improve the efficiency of the support
                    system.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    By integrating with the company’s support system, Knowledge
                    AI can{" "}
                    <a href="https://workativ.com/conversational-ai-platform/blog/rag-user-support">
                      {" "}
                      ingest company proprietary data
                    </a>{" "}
                    and help users become aware of company policy, culture, and
                    goals, helping them engage and thrive.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    With each piece of information being designed to reflect the
                    company’s progress, project status, etc., stakeholders can
                    stay on the same page, improve coordination, and facilitate
                    growth.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Ways of elevating employee support for resolution of user
                queries with Knowledge AI (use cases )
              </h2>
              <img src={section_2} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI is a real boon to augment the capacity of employee
                support. Just as a typical chat support fails to comply with
                user expectations, it fails you in every quarter — from
                preventing your employees from achieving productivity
                satisfaction to delivering customer service, and so on.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s the way to maximize your employee chat support using
                Knowledge AI.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Keep track of projects
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Enhancing productivity for a project's success equals a team’s
                success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                How can you do it if your chatbot is too rigid and rudimentary
                to talk to your internal systems?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI offers{" "}
                <a href="https://workativ.com/conversational-ai-platform/integrations">
                  seamless integrations
                </a>{" "}
                with internal business document systems such as Google Drive,
                OneDrive, SharePoint, Confluence, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is helpful in fetching information for an ongoing project
                and finding status updates. By keeping everyone in the loop,
                Knowledge AI helps gain accelerated know-how project status and
                make iterative strides when needed at scale.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Make stakeholders aware of business developments
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI makes information easily searchable and navigable
                via ChatGPT-like information delivery capacity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say your sales team needs to follow up with a client regarding a
                project to discuss service pricing plans in an unplanned call.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If a project coordinator has a no-show on this particular day, a
                stakeholder likely needs to become prepared to talk about this
                specific aspect.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Thanks to Knowledge AI. If your internal system gives access to
                Knowledge AI, it produces information instantly in real time,
                along with supporting documents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                So, your team can gain the readiness to attend a sudden client
                call and create a positive impression.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Not just project-related information but all business
                development information can be fetched using Knowledge AI by
                stakeholders.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Share knowledge of internal processes or functions
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                How about keeping your employees waiting for days, if not
                months, for a small piece of information?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ideally, very common employee queries take longer for HR to
                answer. These questions can include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd ">
                <li class="font-section-normal-text-testimonials">
                  ‘How many days of paid leaves for maternity are there?’
                </li>
                <li class="font-section-normal-text-testimonials">
                  ‘How is tax calculated for a specific pay scale?’ and
                </li>
                <li class="font-section-normal-text-testimonials">
                  ‘When can I get reimbursement for a business trip?’
                </li>
                <li class="font-section-normal-text-testimonials">
                  ‘How can I get a high-resolution monitor for my graphics
                  works?’
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Integrating Knowledge AI with your employee support systems
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-hr-support">
                  saves your HR team time
                </a>{" "}
                while helping share information on every crucial topic, such as
                finance, tax, company culture, etc.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Educate employees about company culture
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Not every time are your support teams ready to help solve a
                common problem for an employee on company culture.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A typical chatbot can be redundant at a certain point to impede
                the productivity and efficiency of an employee by surfacing
                lengthy content.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If an employee wants to know specific guidelines to apply for a
                leave for a trip, seek special permission to work from home or
                get access to internal business applications, they need
                information that helps clear doubts.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI produces clear and concise information from the
                company data repository without employees needing to reach
                through lengthy content.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They can save time by getting real-time information while
                improving productivity.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Provide hyper-automation through a Knowledge AI self-service
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of asking to rephrase questions to your employees,
                leverage hyper-automation to accelerate the pace of operational
                processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/self-service-automation">
                  Knowledge AI-backed self-service
                </a>{" "}
                improves efficiency, streamlines processes for mundane workflows
                across IT or HR departments and allows your employees to solve
                problems efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This gives the employee support ample opportunities to find
                solutions to common problems early and reduces the chance of
                connecting with tier 1 or 2 contacts.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Deliver bespoke IT support
              </h3>
              <img src={section_3} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Your team’s productivity excels at scale as long as you provide
                efficient{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                  IT support
                </a>{" "}
                to reduce downtime and improve the uptime of your systems and
                applications.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unlike a typical chatbot for ITSM that makes information not so
                useful for users, Knowledge AI understands the context and
                intent of a user’s query and finds the perfect match for it.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, a predefined workflow can trigger a{" "}
                <a href="https://workativ.com/use-cases/reset-password-automation">
                  ‘password reset'
                </a>{" "}
                process via a link. Even after the reset, if a user cannot
                access that application, Knowledge AI helps fetch information to
                address the issue and reduce downtime.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Streamline HR workflows
              </h3>
              <img src={section_4} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Writing emails to candidates, connecting with new hires, and
                helping them onboard are all synonymous with what an HR does
                daily.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Internal chatbots can streamline these processes, but they
                possess limited automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, most organizations automate paperwork.
                Unfortunately, they still need manual intervention to send the
                file through email.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI can automate end-to-end paperwork tasks — from
                sending emails to inviting users to do the necessary functions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Besides this, Knowledge AI offers full{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  HR support for mundane tasks
                </a>{" "}
                such as user onboarding and offboarding.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Unify information for agents
              </h3>
              <img src={section_5} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                It is hard for agents to aggregate information as users share
                their problems via multiple channels.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unable to connect the dots between what one shared on one
                channel and the other, agents have unclear views about users’
                problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI unifies information from various platforms and
                provides omnichannel experience to solve problems in real time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, the ability to craft follow-up messages or provide
                insights into how they tackle upcoming problems reduces agents’
                involvement.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ as a catalyst to promote strategic synergy for
                businesses with Knowledge AI
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ
                </a>{" "}
                helps businesses fuse Knowledge AI and employee support, making
                it easy to increase process efficiency and operation resilience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                By empowering the employee support team with Knowledge AI,
                Workativ crafts enriching experiences by combining human-machine
                power for the seamless resolution of user queries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI enhances information discovery and search
                performance by integrating with company data spread across
                various channels or platforms.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As you build with Knowledge AI, you gain seamless employee
                support benefits —
              </p>
              <ul className="float-left w-100 blogs_ul_upd ">
                <li class="font-section-normal-text-testimonials">
                  Get straightforward and specific answers to your queries
                  instead of vague responses or links to lengthy articles
                </li>
                <li class="font-section-normal-text-testimonials">
                  Build rapid yet effective communications for everyday
                  processes for your employees
                </li>
                <li class="font-section-normal-text-testimonials">
                  Automate common to complex tasks such as sending emails for
                  onboarding, fixing printer issues, provisioning assets, etc
                </li>
                <li class="font-section-normal-text-testimonials">
                  Create as many use cases as you need for your employee support
                </li>
                <li class="font-section-normal-text-testimonials">
                  Build custom workflows for common industry scenarios or
                  domain-specific use cases
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ offers a cost-effective solution to build your
                Knowledge AI for employee support without all the hassles of
                building an LLM solution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Workativ, it is easy to integrate Knowledge AI with your
                employee support and improve interconnectedness among people and
                processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To learn more, visit our{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI page.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to elevate user experience through expedited
                employee support and outstanding partnerships between people,
                Workativ Knowledge AI can help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  {" "}
                  Schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Generative AI — Supercharging your customer support
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Generative AI use cases for customer support efficiency
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Benefits of GenAI-powered customer support for SMBs
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. How do you weave a similar customer experience or support
                    for your internal users with Workativ?
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                User queries can comprise different essences. A strategy built
                to satisfy user queries may need a change periodically, too. A
                unique user request aims to leverage a new approach to
                addressing the problem, delivering a consistent experience for
                internal employees, and building robust customer relationships.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Every strategy for synergy a company picks up involves the same
                underlying objective — unified communications and coordination
                between departments, teams, and various operations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At times when{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-dummies">
                  Generative AI
                </a>{" "}
                unleashes phenomenal competencies, Knowledge AI — a custom large
                language model with a company-owned data repository fosters
                unique strategic synergy for business leaders.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Marrying{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                with internal customer support builds unique partnerships
                between a machine and a human, which brings transformational
                change to support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The synergy between machine or Knowledge AI and employee support
                weaves a unique experience for humans by automating multiple
                mundane tasks.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Using human creativity and unique problem-solving skills with
                Knowledge AI helps accelerate automation capabilities in the
                knowledge discovery area and derive critical insights to
                facilitate fantastic business results.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We can say that Knowledge AI or machines and humans are integral
                to each other – they combine to become superpowers and craft
                beautiful experiences for your{" "}
                <a href="https://workativ.com/conversational-ai-platform/workplace-support-bot">
                  employee support.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Implications of overlooking robust partnership of Knowledge AI
                and employee support
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses want processes to be easy and fast for their
                employees so that they can optimize their productivity and drive
                tangible outcomes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge is critical — and your chatbot emerges as the first
                point of contact to help address a problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                What if your chatbot supplies information that lacks context —
                it frustrates your people and drains their time.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Operational efficiency is at stake. Your{" "}
                    <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                      employee support chatbot
                    </a>{" "}
                    does not provide enough information to help solve a problem.
                    For example, a user shuffles through different articles to
                    get access to business applications. It contains many pages
                    to navigate, but the correct answers sit aloof. Your
                    self-service portal fails you, plus triggers challenges for
                    your agent. Overall, your team sustains a loss of
                    operational efficiency.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Employee morale takes a hit. A rule-based or keyword-based
                    chatbot lacks contextual awareness and intent
                    identification. Employees become frustrated as they must
                    repeatedly type before seeing a rather disappointed
                    response.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Brand loyalty can also drop as employees exhibit less
                    interest in becoming loyal advocates and promoting the brand
                    among its community and groups.
                  </span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI supercharges the availability of information and
                acts as an efficient and powerful component or technology to
                build the finest human-machine relationship to transform
                employee support and provide seamless resolution for user
                queries.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why use Knowledge AI for your employee support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Your employee support could emerge as the best medium to act as
                an effective synergy between your company and internal users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But how often did you realize its answers are complicated,
                incorrect, or irrelevant because it couldn’t recall what was
                said earlier in a chat session?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is a possible scenario with a chatbot for an employee
                support system.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI empowers your{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-automation">
                  employee support system
                </a>{" "}
                to supply information for employees they want to find and solve
                problems.
              </p>
              <img src={section_1} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                By effectively enhancing communications and coordination for
                every department in your organization, Knowledge AI allows
                people to leverage the benefits of strategic synergy between
                technology and employee support systems.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    With domain-specific knowledge, the{" "}
                    <a href="https://workativ.com/conversational-ai-platform/blog/knowledge-ai-employee-support">
                      Knowledge AI chatbot
                    </a>{" "}
                    can streamline operations for human, increase their
                    productivity, and improve the efficiency of the support
                    system.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    By integrating with the company’s support system, Knowledge
                    AI can
                    <a href="https://workativ.com/conversational-ai-platform/blog/rag-user-support">
                      {" "}
                      ingest company proprietary data
                    </a>{" "}
                    and help users become aware of company policy, culture, and
                    goals, helping them engage and thrive.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    With each piece of information being designed to reflect the
                    company’s progress, project status, etc., stakeholders can
                    stay on the same page, improve coordination, and facilitate
                    growth.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Ways of elevating employee support for resolution of user
                queries with Knowledge AI (use cases )
              </h2>
              <img src={section_2} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI is a real boon to augment the capacity of employee
                support. Just as a typical chat support fails to comply with
                user expectations, it fails you in every quarter — from
                preventing your employees from achieving productivity
                satisfaction to delivering customer service, and so on.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s the way to maximize your employee chat support using
                Knowledge AI.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Keep track of projects
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Enhancing productivity for a project's success equals a team’s
                success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                How can you do it if your chatbot is too rigid and rudimentary
                to talk to your internal systems?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI offers{" "}
                <a href="https://workativ.com/conversational-ai-platform/integrations">
                  seamless integrations
                </a>{" "}
                with internal business document systems such as Google Drive,
                OneDrive, SharePoint, Confluence, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is helpful in fetching information for an ongoing project
                and finding status updates. By keeping everyone in the loop,
                Knowledge AI helps gain accelerated know-how project status and
                make iterative strides when needed at scale.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Make stakeholders aware of business developments
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI makes information easily searchable and navigable
                via ChatGPT-like information delivery capacity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say your sales team needs to follow up with a client regarding a
                project to discuss service pricing plans in an unplanned call.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If a project coordinator has a no-show on this particular day, a
                stakeholder likely needs to become prepared to talk about this
                specific aspect.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Thanks to Knowledge AI. If your internal system gives access to
                Knowledge AI, it produces information instantly in real time,
                along with supporting documents.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                So, your team can gain the readiness to attend a sudden client
                call and create a positive impression.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Not just project-related information but all business
                development information can be fetched using Knowledge AI by
                stakeholders.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Share knowledge of internal processes or functions
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                How about keeping your employees waiting for days, if not
                months, for a small piece of information?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ideally, very common employee queries take longer for HR to
                answer. These questions can include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd ">
                <li class="font-section-normal-text-testimonials">
                  ‘How many days of paid leaves for maternity are there?’
                </li>
                <li class="font-section-normal-text-testimonials">
                  ‘How is tax calculated for a specific pay scale?’ and
                </li>
                <li class="font-section-normal-text-testimonials">
                  ‘When can I get reimbursement for a business trip?’
                </li>
                <li class="font-section-normal-text-testimonials">
                  ‘How can I get a high-resolution monitor for my graphics
                  works?’
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Integrating Knowledge AI with your employee support systems
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-hr-support">
                  saves your HR team time
                </a>{" "}
                while helping share information on every crucial topic, such as
                finance, tax, company culture, etc.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Educate employees about company culture
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Not every time are your support teams ready to help solve a
                common problem for an employee on company culture.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A typical chatbot can be redundant at a certain point to impede
                the productivity and efficiency of an employee by surfacing
                lengthy content.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If an employee wants to know specific guidelines to apply for a
                leave for a trip, seek special permission to work from home or
                get access to internal business applications, they need
                information that helps clear doubts.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI produces clear and concise information from the
                company data repository without employees needing to reach
                through lengthy content.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They can save time by getting real-time information while
                improving productivity.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Provide hyper-automation through a Knowledge AI self-service
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of asking to rephrase questions to your employees,
                leverage hyper-automation to accelerate the pace of operational
                processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/self-service-automation">
                  Knowledge AI-backed self-service
                </a>{" "}
                improves efficiency, streamlines processes for mundane workflows
                across IT or HR departments and allows your employees to solve
                problems efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This gives the employee support ample opportunities to find
                solutions to common problems early and reduces the chance of
                connecting with tier 1 or 2 contacts.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Deliver bespoke IT support
              </h3>
              <img src={section_3} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Your team’s productivity excels at scale as long as you provide
                efficient{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                  IT support
                </a>{" "}
                to reduce downtime and improve the uptime of your systems and
                applications.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unlike a typical chatbot for ITSM that makes information not so
                useful for users, Knowledge AI understands the context and
                intent of a user’s query and finds the perfect match for it.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, a predefined workflow can trigger a{" "}
                <a href="https://workativ.com/use-cases/reset-password-automation">
                  ‘password reset'
                </a>{" "}
                process via a link. Even after the reset, if a user cannot
                access that application, Knowledge AI helps fetch information to
                address the issue and reduce downtime.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Streamline HR workflows
              </h3>
              <img src={section_4} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Writing emails to candidates, connecting with new hires, and
                helping them onboard are all synonymous with what an HR does
                daily.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Internal chatbots can streamline these processes, but they
                possess limited automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, most organizations automate paperwork.
                Unfortunately, they still need manual intervention to send the
                file through email.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI can automate end-to-end paperwork tasks — from
                sending emails to inviting users to do the necessary functions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Besides this, Knowledge AI offers full{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  HR support for mundane tasks
                </a>{" "}
                such as user onboarding and offboarding.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Unify information for agents
              </h3>
              <img src={section_5} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                It is hard for agents to aggregate information as users share
                their problems via multiple channels.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unable to connect the dots between what one shared on one
                channel and the other, agents have unclear views about users’
                problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI unifies information from various platforms and
                provides omnichannel experience to solve problems in real time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, the ability to craft follow-up messages or provide
                insights into how they tackle upcoming problems reduces agents’
                involvement.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ as a catalyst to promote strategic synergy for
                businesses with Knowledge AI
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ
                </a>{" "}
                helps businesses fuse Knowledge AI and employee support, making
                it easy to increase process efficiency and operation resilience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                By empowering the employee support team with Knowledge AI,
                Workativ crafts enriching experiences by combining human-machine
                power for the seamless resolution of user queries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI enhances information discovery and search
                performance by integrating with company data spread across
                various channels or platforms.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As you build with Knowledge AI, you gain seamless employee
                support benefits —
              </p>
              <ul className="float-left w-100 blogs_ul_upd ">
                <li class="font-section-normal-text-testimonials">
                  Get straightforward and specific answers to your queries
                  instead of vague responses or links to lengthy articles
                </li>
                <li class="font-section-normal-text-testimonials">
                  Build rapid yet effective communications for everyday
                  processes for your employees
                </li>
                <li class="font-section-normal-text-testimonials">
                  Automate common to complex tasks such as sending emails for
                  onboarding, fixing printer issues, provisioning assets, etc
                </li>
                <li class="font-section-normal-text-testimonials">
                  Create as many use cases as you need for your employee support
                </li>
                <li class="font-section-normal-text-testimonials">
                  Build custom workflows for common industry scenarios or
                  domain-specific use cases
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ offers a cost-effective solution to build your
                Knowledge AI for employee support without all the hassles of
                building an LLM solution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Workativ, it is easy to integrate Knowledge AI with your
                employee support and improve interconnectedness among people and
                processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To learn more, visit our{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI page.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to elevate user experience through expedited
                employee support and outstanding partnerships between people,
                Workativ Knowledge AI can help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  {" "}
                  Schedule a demo today.{" "}
                </a>
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/roi-chatbot-automation-it-service-desk"
              className="font-section-normal-text-testimonials"
            >
              The ROI of Using Chatbot and Automation with Your IT Service Desk​
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management"
              className="font-section-normal-text-testimonials"
            >
              AI in ITSM: Unlocking the Value of AI for IT Service Management
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/onboarding-challenges-it-service-desk-face-with-new-agents"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Onboarding Challenges IT Service Desk Face with New Agents
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
